import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'

interface LandingProps {
  navigate: any
  params: any
}

type LandingStates = {

}

class Landing extends Component <LandingProps, LandingStates> {
  constructor (props: LandingProps) {
    super(props)
    this.state = {

    }
  }

  render () {
    return (
      <div className="flex min-h-full items-center justify-center min-h-screen px-2 pt-10 pb-2 bg-gray-900">
        
      </div>
    );
  }
}

export default function LandingWithBonus() {
   const params = useParams()
   const navigate = useNavigate()
   return <Landing params={params} navigate={navigate}/>
 }