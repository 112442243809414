import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials, getDisplayBalance } from './utils'

interface ReloadingProps {
  navigate: any
  params: any
}

type ReloadingStates = {
  amount: number
  minimum: number
  user: any
}

class Reloading extends Component <ReloadingProps, ReloadingStates> {
  constructor (props: ReloadingProps) {
    super(props)
    this.state = {
      amount: 0,
      minimum: 0,
      user: undefined
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  componentDidMount () {
    this.loadProfile()
  }

  loadProfile = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            minimum: json.data.minimum === null ? 0 : parseInt(getDisplayBalance(json.data.minimum)),
            amount: json.data.amount === null ? 0 : parseInt(getDisplayBalance(json.data.amount)),
            user: json.data
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  saveProfile = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          ...this.state.user,
          minimum: this.state.minimum * 100000,
          amount: this.state.amount * 100000
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.props.navigate('/manage')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  render () {
    return (
      <div className='container-flexible flex flex-col min-h-screen px-2 pt-10 pb-2 bg-gray-900'>

        <div className='text-lg font-bold border-b border-gray-500 pb-4 flex flex-row text-white'>
          <div onClick={()=>this.props.navigate('/dashboard')} className='p-1 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
              <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
            </svg>
          </div>
          <div>
            Reloading
          </div>
        </div>

        <div className="flex grow flex-col justify-evenly pb-10 pt-5">

          <div className='text-gray-100'>Reloading system automatically charges credit/debit card and reloads the balance. Reloading is executed when balance drops below the minimum option and reloads for amount defined.</div>

          <div>
            <div className='text-white font-bold pb-2 pt-5'>Minimum</div>
            <div className="mt-1 flex rounded-md shadow-sm">
              <div className="relative flex flex-grow items-stretch">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-500 px-3 text-gray-300 uppercase">
                  $
                </span>
                <input
                  value={this.state.minimum}
                  onChange={this.inputChange}
                  name="minimum"
                  type="number"
                  className="block w-full rounded-r-md border-0 bg-gray-900 text-white ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500"
                  placeholder="1"
                />
              </div>
            </div>
          </div>
          
          <div>
            <div className='text-white font-bold pb-2 pt-5'>Amount</div>
            <div className="mt-1 flex rounded-md shadow-sm">
              <div className="relative flex flex-grow items-stretch">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-500 px-3 text-gray-300 uppercase">
                  $
                </span>
                <input
                  value={this.state.amount}
                  onChange={this.inputChange}
                  name="amount"
                  type="number"
                  className="block w-full rounded-r-md border-0 bg-gray-900 text-white ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500"
                  placeholder="10"
                />
              </div>
            </div>
          </div>
          
          <button
            onClick={()=>this.saveProfile()}
            type="button"
            className="bg-red-500 hover:bg-red-400 text-white rounded items-center px-4 py-2 w-6/12 mt-7"
          >
            Save Reloading
          </button>

        </div>
        
      </div>
    );
  }
}

export default function ReloadingWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <Reloading params={params} navigate={navigate}/>
}