import React from 'react';
import ReactDOM from 'react-dom/client';
// import * as serviceWorker from './serviceWorker';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import Render from './Render';
import Loader from './Loader';
import Signin from './Signin';
import Signup from './Signup';
import Settings from './Settings';
import Forgot from './Forgot';
import Reset from './Reset';
import Dashboard from './Dashboard';
import AddCard from './AddCard';
import Landing from './Landing';
import Reloading from './Reloading';
import Manage from './Manage';
import Update from './Update';

import './index.scss';

const router = createBrowserRouter([{
  path: "/render",
  element: <Render/>
},{
  path: "/loader",
  element: <Loader/>
},{
  path: "/dashboard",
  element: <Dashboard/>
},{
  path: "/signin",
  element: <Signin/>
},{
  path: "/signup",
  element: <Signup/>
},{
  path: "/forgot",
  element: <Forgot/>
},{
  path: "/reset",
  element: <Reset/>
},{
  path: "/settings",
  element: <Settings/>
},{
  path: "/addcard",
  element: <AddCard/>
},{
  path: "/reloading",
  element: <Reloading/>
},{
  path: "/manage",
  element: <Manage/>
},{
  path: "/update",
  element: <Update/>
},{
  path: "/",
  element: <Landing/>
}]);

const root = ReactDOM.createRoot(document.getElementById('root')  as HTMLElement);
root.render(
  // <React.StrictMode>
  <HelmetProvider>
    <RouterProvider router={router} />
  </HelmetProvider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
