import React, { Component } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

interface UpdateProps {
  navigate: any
  params: any
}

type UpdateStates = {

}

class Update extends Component <UpdateProps, UpdateStates> {
  constructor (props: UpdateProps) {
    super(props)
    this.state = {

    }
  }

  render () {
    return (
      <div className="flex min-h-full items-center justify-center min-h-screen px-2 pt-10 pb-2 bg-gray-900">
        <div className="w-full max-w-sm space-y-10 max-w-[400px]">
          <div className='text-white text-xl font-bold w-full flex flex-col items-center justify-center'>
            <div>Visit <span className='text-red-500'>aurore.ai</span></div>
            <div>and download</div>
            <div>updated client</div>
          </div>
        </div>
      </div>
    );
  }
}

export default function UpdateWithBonus() {
   const params = useParams()
   const navigate = useNavigate()
   return <Update params={params} navigate={navigate}/>
 }