import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'
import { getDisplayBalance } from './utils'
import { KeyIcon, PlayCircleIcon, StopCircleIcon, Cog6ToothIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import female1 from './images/female1.png'
import female2 from './images/female2.png'
import female3 from './images/female3.png'

const femaleModels: any = {
  'female1': { id: 'female1', name: 'Visual 1', image: female1 },
  'female2': { id: 'female2', name: 'Visual 2', image: female2 },
  'female3': { id: 'female3', name: 'Visual 3', image: female3 }
}

interface DashboardProps {
  navigate: any
  params: any
}

type DashboardStates = {
  user: any
  subscription: any
  model: any
  micAccess: any
  screenAccess: any
}

class Dashboard extends Component <DashboardProps, DashboardStates> {
  interval: any = undefined
  constructor (props: DashboardProps) {
    super(props)
    this.state = {
      user: undefined,
      subscription: undefined,
      model: undefined,
      micAccess: undefined,
      screenAccess: undefined,
    }
  }

  componentDidMount(){
    this.loadProfile()
    this.getAccess()
    this.getVersion()
  }
  
  getVersion = async () => {
    const version = await (window as any).electronAPI.requestVersion()
    if (version !== '0.2.0') {
      this.props.navigate('/update')
    }
  }
  
  componentWillUnmount(): void {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  requestMicAccess = async () => {
    (window as any).electronAPI.requestAccess('microphone')
  }
  
  requestScreenAccess = async () => {
    (window as any).electronAPI.getSources()
  }

  getAccess = async () => {
    try {
      const accesses = await (window as any).electronAPI.getAccess()
      if ((accesses.micAccess === false || accesses.screenAccess === false) && this.interval === undefined) {
        this.interval = setInterval(()=>this.getAccess(), 1000)
      }
      this.setState({
        micAccess: accesses.micAccess,
        screenAccess: accesses.screenAccess
      })
    } catch (e) {
      console.log('no access microphone', e)
    }
  }

  loadProfile = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            user: json.data
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  render () {
    return (
      <div className='container-flexible flex flex-col min-h-screen px-2 pt-10 pb-2 bg-gray-900'>

        <div className='text-lg font-bold border-b border-gray-500 pb-4 text-white'>Hello, {this.state.user && this.state.user.fullname ? this.state.user.fullname : null}</div>

        <div className="flex grow flex-col justify-evenly">

          <div className='flex'>
            
            <img
              className="w-3/12"
              src={this.state.user && femaleModels[this.state.user.model] ? femaleModels[this.state.user.model].image : ''}
              alt=""
            />

            <div className='w-9/12 flex flex-col'>
              <div className="text-lg font-bold text-white">I'm Aurore</div>
              <div className="pb-4 text-sm text-gray-300">Setup interaction in settings and then press start!</div>
              <button
                onClick={()=>this.props.navigate('/settings')}
                className="bg-white text-gray-900 rounded px-2 py-1 ring-1 ring-inset ring-gray-300 me-2"
              >
                <div className='flex justify-center'>
                  <div className='pr-2'>
                    <Cog6ToothIcon className="mt-0.5 w-5 h-5"/>
                  </div>
                  <div>Settings</div>
                </div>
              </button>
            </div>
          </div>

          <div>
            <div className='text-lg font-bold pb-2 text-white'>
              Balance ${this.state.user && this.state.user.balance ? getDisplayBalance(this.state.user.balance) : 0}
            </div>
            <div className='text-sm text-gray-300'>
              Balance is used as a currency within the system, with each interaction costing a fraction of cents.
            </div>
            <div>
              <button
                  onClick={()=>this.props.navigate('/manage')}
                  type="button"
                  className="bg-white text-gray-900 rounded items-center ring-1 ring-inset ring-gray-300 px-2 py-1 w-4/12 mt-2"
              >
                Manage
              </button>
            </div>
          </div>

          <div className='pt-7 flex justify-center pb-1'>
            <button
              onClick={()=>(window as any).electronAPI.stopRenderer()}
              className="bg-white text-gray-900 rounded items-center px-4 py-2 ring-1 ring-inset ring-gray-300 me-2 w-3/12"
            >
              <div className='flex flex-row justify-center'>
                <div className='pr-2'>
                  <StopCircleIcon className="mt-0.5 w-5 h-5"/>
                </div>
                <div>
                  Stop
                </div>
              </div>
            </button>
            <button
              onClick={()=>(window as any).electronAPI.startRenderer({token: getCredentials()})}
              className="bg-red-500 hover:bg-red-400 text-white rounded items-center px-4 py-2 w-9/12"
              disabled={this.state.micAccess === false || this.state.screenAccess === false}
            >
              <div className='flex flex-row justify-center'>
                <div className='pr-2'>
                  <PlayCircleIcon className="mt-0.5 w-5 h-5"/>
                </div>
                <div>
                  Start
                </div>
              </div>
            </button>
          </div>
          
          <div>
            {this.state.user && this.state.user.balance <= 0 ?
              <div className="rounded-md bg-yellow-50 p-2 mt-2">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-600" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">Your Balance is Empty</h3>
                  </div>
                </div>
              </div>
            :null}
            
            {this.state.screenAccess === false ?
              <div className="rounded-md bg-yellow-50 p-2 mt-2">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-600" />
                  </div>
                  <div className="ml-3 text-sm font-medium text-yellow-800 flex w-full flex-row justify-between">
                    <div>Screen access is missing!</div>
                    <div className="cursor-pointer flex" onClick={()=>this.requestScreenAccess()}><KeyIcon className="w-4 h-4 mt-0.5 mr-2"/>Request Access</div>
                  </div>
                </div>
              </div>
            :null}
            
            {this.state.micAccess === false ?
              <div className="rounded-md bg-yellow-50 p-2 mt-2">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-600" />
                  </div>
                  <div className="ml-3 text-sm font-medium text-yellow-800 flex w-full flex-row justify-between">
                    <div>Mic access is missing!</div>
                    <div className="cursor-pointer flex" onClick={()=>this.requestMicAccess()}><KeyIcon className="w-4 h-4 mt-0.5 mr-2"/>Request Access</div>
                  </div>
                </div>
              </div>
            :null}
          </div>
          
        </div>
      </div>
    );
  }
}

export default function DashboardWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <Dashboard params={params} navigate={navigate}/>
}