import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { RadioGroup } from '@headlessui/react'
import { getCredentials } from './utils'
import { CheckCircleIcon, TrashIcon } from '@heroicons/react/20/solid'
import { MicVAD } from "@ricky0123/vad-web"
import { KeyIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import female1 from './images/female1.png'
import female2 from './images/female2.png'
import female3 from './images/female3.png'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface SettingsProps {
  navigate: any
  params: any
}

type SettingsStates = {
  model: string
  voice: string
  threshold: number
  minimum: any
  amount: any
  logErrors: any[]
  logError: string
  micAccess: any
  speakingProbability: number
}

class Settings extends Component <SettingsProps, SettingsStates> {
  interval: any = undefined
  constructor (props: SettingsProps) {
    super(props)
    this.state = {
      model: '',
      voice: 'voice',
      threshold: 0,
      minimum: null,
      amount: null,
      logErrors: [],
      logError: '',
      micAccess: undefined,
      speakingProbability: 0
    }
  }

  componentDidMount(){
    this.loadProfile()
    this.getAccess()
  }
  
  componentWillUnmount(): void {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
  
  getAccess = async () => {
    try {
      const accesses = await (window as any).electronAPI.getAccess()
      if ((accesses.micAccess === false || accesses.screenAccess === false) && this.interval === undefined) {
        this.interval = setInterval(()=>this.getAccess(), 1000)
      }
      if (accesses.micAccess === true) {
        this.startListening()
      }
      this.setState({
        micAccess: accesses.micAccess
      })
    } catch (e) {
      console.log('no access microphone', e)
    }
  }

  startListening = async () => {
    const vad = await MicVAD.new({
      additionalAudioConstraints: {sampleRate: 16000, sampleSize: 32, channelCount: 1, echoCancellation: true} as any,
      onFrameProcessed: (frame) => {
        this.setState({speakingProbability: frame.isSpeech * 100})
      }
    })
    vad.start()
  }
  
  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  loadProfile = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            model: json.data.model,
            threshold: json.data.threshold / 100,
            minimum: json.data.minimum,
            amount: json.data.amount
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  saveProfile = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          model: this.state.model,
          threshold: this.state.threshold * 100,
          minimum: this.state.minimum,
          amount: this.state.amount
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.props.navigate('/dashboard')
        } else {
          this.setState({
            logErrors: json.errors,
            logError: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  femaleModels = [
    { id: 'female1', name: 'Visual 1', image: female1 },
    { id: 'female2', name: 'Visual 2', image: female2 },
    { id: 'female3', name: 'Visual 3', image: female3 }
  ]
  
  audioOptions = [
    { id: 'voice', name: 'Voice detection', body: () => {
      return <div className="pb-2">
        <div className="pt-2 text-sm text-gray-100 pb-1">Detection threshold: {this.state.threshold}</div>
        <input
          onChange={(threshold)=>this.setState({threshold: parseFloat(threshold.currentTarget.value)})}
          type="range"
          min={0}
          max={1}
          step={0.01}
          value={this.state.threshold}
          className="inputrange transparent h-[4px] w-full cursor-pointer appearance-none border-transparent bg-neutral-200 dark:bg-neutral-600 mb-4"
          style={{
            background: `linear-gradient(to right, rgb(239, 68, 68) 0%, rgb(239, 68, 68) ${this.state.speakingProbability+'%'}, #666 ${this.state.speakingProbability+'%'}, #666 100%)`
          }}
        />
      </div>
    }}
  ]
  
  requestMicAccess = async () => {
    (window as any).electronAPI.requestAccess('microphone')
  }

  render () {
    return (
      <div className='container-flexible flex flex-col min-h-screen px-2 pt-10 bg-gray-900'>

        <div className='text-lg text-white font-bold border-b border-gray-500 pb-4 flex flex-row'>
          <div onClick={()=>this.props.navigate('/dashboard')} className='p-1 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
              <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="text-white">
            Settings
          </div>
        </div>

        <div className="flex grow flex-col justify-evenly">

          <div className="mt-3">
            <RadioGroup value={this.state.model} onChange={(model)=>this.setState({model})}>
              <RadioGroup.Label className="text-white font-bold text-lg">Desired visuals</RadioGroup.Label>
              <div className="mt-3 grid gap-y-6 grid-cols-3 sm:gap-x-4 gap-x-2">
                {this.femaleModels.map((femaleModel: any) => (
                  <RadioGroup.Option
                    key={femaleModel.id}
                    value={femaleModel.id}
                    className={({ checked, active }) =>
                      classNames(
                        this.state.model === femaleModel.id ? 'ring-2 ring-red-500' : 'ring-1 ring-gray-500',
                        'relative flex cursor-pointer rounded border border-gray-900 bg-white focus:outline-none'
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <div className="relative w-full pt-2 pe-2 ps-2 bg-gray-900">
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <RadioGroup.Label as="span" className="block text-white">
                              {femaleModel.name}
                            </RadioGroup.Label>
                          </span>
                        </span>
                        {checked ? <CheckCircleIcon className="h-5 w-5 text-red-600 absolute top-0 right-0" aria-hidden="true" /> : null}
                        <div className="flex justify-center">
                          <img src={femaleModel.image} alt=""/>
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>

          <div className="mt-3 pt-3">
            <RadioGroup value={this.state.voice} onChange={(voice)=>this.setState({voice})}>
              <RadioGroup.Label className="text-white font-bold text-lg">Audio options</RadioGroup.Label>
              <div className="mt-3 grid gap-y-6 grid-cols-1 sm:gap-x-4 gap-x-2">
                {this.audioOptions.map((audioOption: any) => (
                  <RadioGroup.Option
                    key={audioOption.id}
                    value={audioOption.id}
                    className={({ checked, active }) =>
                      classNames(
                        this.state.voice === audioOption.id ? 'ring-2 ring-red-500' : 'ring-1 ring-gray-500',
                        'relative flex cursor-pointer rounded border border-gray-900 bg-white focus:outline-none'
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <div className="relative w-full bg-gray-900 pt-2 pe-2 ps-2">
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <RadioGroup.Label as="span" className="block text-white">
                              {audioOption.name}
                            </RadioGroup.Label>
                          </span>
                        </span>
                        {checked ? <CheckCircleIcon className="h-5 w-5 text-red-500 absolute top-0 right-0" aria-hidden="true" /> : null}
                        {audioOption.body()}
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          
          {this.state.micAccess === false ?
              <div className="rounded-md bg-yellow-50 p-2 mt-2">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-600" />
                  </div>
                  <div className="ml-3 text-sm font-medium text-yellow-800 flex w-full flex-row justify-between">
                    <div>Mic access is missing!</div>
                    <div className="cursor-pointer flex" onClick={()=>this.requestMicAccess()}><KeyIcon className="w-4 h-4 mt-0.5 mr-2"/>Request Access</div>
                  </div>
                </div>
              </div>
            :null}

          <div className='pt-7 pb-2 flex justify-center'>
            <button
              onClick={()=>this.props.navigate('/dashboard')}
              className="bg-white text-gray-900 rounded items-center px-4 py-2 ring-1 ring-inset ring-gray-300 me-2 w-3/12"
            >
              Cancel
            </button>
            <button
              onClick={()=>this.saveProfile()}
              className="bg-red-500 hover:bg-red-400 text-white rounded items-center px-4 py-2 w-9/12"
            >
              Save
            </button>
          </div>

        </div>

      </div>
    );
  }
}

export default function SettingsWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <Settings params={params} navigate={navigate}/>
}