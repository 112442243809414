export const getCredentials = () => {
  const rawAccounts = localStorage.getItem('accounts')!
  const accounts = JSON.parse(rawAccounts)
  if (accounts && accounts.token) {
    return accounts.token
  }
  return false
}

export const storeCredentials = (token: string) => {
  localStorage.setItem('accounts', JSON.stringify({ token }))
}

export const deleteCredentials = () => {
  localStorage.setItem('accounts', JSON.stringify({}))
}

export const getDisplayBalance = (balance: number) => {
  return (balance / 100000).toFixed(5).slice(0,-3)
}