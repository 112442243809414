import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials, deleteCredentials } from './utils'
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

interface AddCardProps {
  navigate: any
  params: any
}

type AddCardStates = {
  stripe: any
  stripeSecret: any
  isLoading: boolean
  addingSuccess: boolean
}

class AddCard extends Component <AddCardProps, AddCardStates> {
  constructor (props: AddCardProps) {
    super(props)
    this.state = {
      stripe: undefined,
      stripeSecret: undefined,
      isLoading: false,
      addingSuccess: false
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  componentDidMount () {
    this.addCard()
  }

  addCard = () => {
    const token = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/payment/cards', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          const stripe = await loadStripe(json.stripeKey)
          this.setState({
            stripe,
            stripeSecret: json.clientSecret
          })
        }
        if (json.status === 'nouser') {
          deleteCredentials()
          this.props.navigate('/signin')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  render () {
    return (
      <div className='container-flexible flex flex-col min-h-screen px-2 pt-10 pb-2 bg-gray-900'>

        <div className='text-lg font-bold border-b border-gray-500 pb-4 flex flex-row text-white'>
          <div onClick={()=>this.setState({addingSuccess: false}, ()=>this.props.navigate('/dashboard'))} className='p-1 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
              <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
            </svg>
          </div>
          <div>
            Add Card
          </div>
        </div>

        <div className="flex grow flex-col justify-center pb-10 pt-5">
          { this.state.addingSuccess === false && this.state.stripe && this.state.stripeSecret ?
            <EmbeddedCheckoutProvider
              stripe={this.state.stripe}
              options={{
                clientSecret: this.state.stripeSecret,
                onComplete: ()=>this.setState({addingSuccess: true})
              }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          : null}
          { this.state.addingSuccess === true ?
            <div className='text-white text-center'>
              <div className="mt-10 font-bold">Success!</div>
              <div className="mt-4">It may take few moments to reflect</div>
              <div>adding card to our system</div>
              <div className="mt-8 flex justify-center items-center">
                <button
                  type="button"
                  onClick={()=>this.props.navigate('/manage')}
                  className="block rounded-md bg-red-500 px-5 py-2 text-center font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                >
                  Back
                </button>
              </div>
            </div>
          : null}
        </div>
      </div>
    );
  }
}

export default function AddCardWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <AddCard params={params} navigate={navigate}/>
}