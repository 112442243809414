import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials, getDisplayBalance } from './utils'
import { Switch } from '@headlessui/react'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface ManageProps {
  navigate: any
  params: any
}

type ManageStates = {
  user: any
}

class Manage extends Component <ManageProps, ManageStates> {
  constructor (props: ManageProps) {
    super(props)
    this.state = {
      user: undefined
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as any)
  }

  componentDidMount () {
    this.loadProfile()
  }

  loadProfile = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            user: json.data
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  saveProfile = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          // ...this.state.user,
          // minimum: this.state.minimum * 100000,
          // amount: this.state.amount * 100000
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.props.navigate('/dashboard')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  disableAds = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me/disableads', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        },
        body: JSON.stringify({
          adsdisabled: !this.state.user.adsdisabled
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadProfile()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  removeCard = (cardId: string) => {
    const token = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/payment/cards/'+cardId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.loadProfile()
        }
        if (json.status === 'nouser') {
          this.props.navigate('/signin')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  render () {
    let adsdisabled = !false
    if (this.state.user) {
      adsdisabled = !this.state.user.adsdisabled
    }
    return (
      <div className='container-flexible flex flex-col min-h-screen px-2 pt-10 pb-2 bg-gray-900'>

        <div className='text-lg font-bold border-b border-gray-500 pb-4 flex flex-row text-white'>
          <div onClick={()=>this.props.navigate('/dashboard')} className='p-1 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
              <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
            </svg>
          </div>
          <div>
            Manage
          </div>
        </div>

        <div className="flex grow flex-col justify-evenly">

          <div>
            <div className='text-lg font-bold pb-2 text-white'>
              1. Add Card
            </div>
            <div className='text-sm text-gray-300'>
              Balance is used as a currency within the system, with each interaction costing a fraction of cents.
            </div>
            
            {this.state.user && this.state.user.cards.length === 0 ?
            <button
              onClick={()=>this.props.navigate('/addcard')}
              type="button"
              className="bg-red-500 hover:bg-red-400 text-white rounded items-center px-2 py-1 w-4/12 mt-2"
            >
              Add Card
            </button>
          :null}
          
          {this.state.user && this.state.user.cards.length > 0 ?
            <div className="mt-2 flex rounded-md">
              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-500 px-3 text-gray-300 uppercase">
                {this.state.user.cards[0].method}
              </span>
              <input
                disabled
                type="text"
                value={"****-****-****-"+this.state.user.cards[0].number+" "+this.state.user.cards[0].expiry}
                className="block w-full min-w-0 flex-1 rounded-none border-0 py-1 text-gray-300 ring-1 ring-inset ring-gray-500 bg-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500"
              />
              <button
                onClick={()=>this.removeCard(this.state.user.cards[0].id)}
                type="button"
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-4 py-1 ring-1 ring-inset ring-gray-500 text-gray-300"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>
              </button>
            </div>
          :null}
          </div>

          <div>
            <div className='text-lg font-bold pb-2 text-white'>
              2. Setup Recharging
            </div>
            <div className='text-sm text-gray-300'>
              Balance is used as a currency within the system, with each interaction costing a fraction of cents.
            </div>
            <button
              onClick={()=>this.props.navigate('/reloading')}
              type="button"
              className="bg-red-500 hover:bg-red-400 text-white rounded items-center px-2 py-1 w-4/12 mt-2"
            >
              Setup Reloading
            </button>
          </div>
          
          <div>
            <div className='text-lg font-bold pb-2 text-white mt-7'>
              3. ADs (optional)
            </div>

            <div className='mb-2 text-sm text-gray-300'>
              Having ads activated will recharge your balance whenever you engage with ad content.
            </div>

            <div className='text-gray-300 flex flex-column'>
              <div className='pr-3'>Ads active:</div>
              <div>
                <Switch
                  checked={adsdisabled}
                  onChange={()=>this.disableAds()}
                  className={classNames(
                    adsdisabled ? 'bg-red-500' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    className={classNames(
                      adsdisabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out'
                    )}
                  >
                    <span
                      className={classNames(
                        adsdisabled ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                      )}
                      aria-hidden="true"
                    >
                      <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                        <path
                          d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span
                      className={classNames(
                        adsdisabled ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                      )}
                      aria-hidden="true"
                    >
                      <svg className="h-3 w-3 text-red-500" fill="currentColor" viewBox="0 0 12 12">
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                      </svg>
                    </span>
                  </span>
                </Switch>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default function ManageWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  return <Manage params={params} navigate={navigate}/>
}