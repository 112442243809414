import React, { Component } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { config } from './config'
import io from "socket.io-client"
import { FreeCamera, Vector3, HemisphericLight, Color4, SceneLoader, Scalar } from "@babylonjs/core";
import SceneComponent from "./SceneComponent";
import { getCredentials } from './utils'
import { MicVAD } from "@ricky0123/vad-web"
import "@babylonjs/loaders/glTF";

interface RendererProps {
  navigate: any
  params: any
  location: any
}

type RendererStates = {
  model: string
  threshold: number
}

class Renderer extends Component <RendererProps, RendererStates> {

  socket: any
  imageCapture: any
  vad: any
  chunksToProcess: string[] = []
  chunksIndex: number = 0
  isProcessingChunk: boolean = false
  animationOffset = 50
  audioCtx = new AudioContext()
  voiceAudio: any
  voiceAudioAnalyser = this.audioCtx.createAnalyser()
  toPlay: any[] = []

  constructor (props: RendererProps) {
    super(props)
    this.state = {
      model: '',
      threshold: 0
    }
  }
  
  componentDidMount(){
    this.loadProfile()
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.disconnect()
    }
    if (this.vad) {
      this.vad.destroy()
    }
  }

  loadProfile = () => {
    const authToken = getCredentials()
    fetch(
      config.app.apiUri + '/api/v1/me', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken
        }
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          this.setState({
            model: json.data.model,
            threshold: json.data.threshold / 100
          }, () => {
            this.connect()
            this.startListening()
            this.beginObserving()
            this.startProcessingChunks()
            this.initAudio()
            this.beginAnimateSpeech()
            this.importModel(this.state.model+".glb");
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  beginObserving = async () => {
    const source = await (window as any).electronAPI.getSources()
    if (source === false) return
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        mandatory: {
          chromeMediaSource: 'desktop',
          chromeMediaSourceId: source.id,
          minWidth: 512,
          maxWidth: 512
        }
      } as any
    })
    const track = stream.getVideoTracks()[0]
    // @ts-ignore
    this.imageCapture = new ImageCapture(track)
  }

  getScreen = async () => {
    const image = await this.imageCapture.grabFrame()
    return await this.convertImageBitmapToJPEGBase64(image)
  }

  convertImageBitmapToJPEGBase64 = async (imageBitmap: any) => {
    const canvas = document.createElement('canvas')
    canvas.width = imageBitmap.width
    canvas.height = imageBitmap.height
    const ctx: any = canvas.getContext('2d')
    ctx.drawImage(imageBitmap, 0, 0)
    const jpegBase64 = canvas.toDataURL('image/jpeg', 0.5)
    return jpegBase64
  }

  startProcessingChunks = () => {
    setInterval(()=>this.processChunk(),100)
  }
  
  processChunk = async () => {
    if (this.chunksToProcess.length > 0 && this.isProcessingChunk === false) {
      this.isProcessingChunk = true
      const chunk = this.chunksToProcess.shift()
      const audio = await (window as any).electronAPI.textToSpeech(chunk)
      const blob = new Blob([audio])
      const url = URL.createObjectURL(blob)
      this.toPlay = [...this.toPlay, url]
      // play if there is
      if (this.voiceAudio.src === "data:audio/wav;base64,") {
        const play = this.toPlay.shift()
        this.voiceAudio.src = play
      }
      this.isProcessingChunk = false
    }
  }

  initAudio = () => {
    this.voiceAudio = new Audio()
    this.voiceAudio.autoplay = true
    this.voiceAudio.src = "data:audio/wav;base64," 
    this.voiceAudio.onplay = () => {
      this.talking = true
      this.audioCtx.resume()
      this.removeAnimObservers()
    }
    this.voiceAudio.onended = () => {
      if (this.toPlay.length > 0) {
        const play = this.toPlay.shift()
        this.voiceAudio.src = play
      } else {
        this.voiceAudio.src = "data:audio/wav;base64,"
        this.talking = false
        this.audioCtx.suspend()
        this.setIdleAnimObservers()
        this.scene.onBeforeRenderObservable.runCoroutineAsync(this.animationBlending(this.currentAnimation, 0.7, this.idle1, 0.7, false, 0.02, 0, this.idle1.duration, 0.8))
      }
    }
    const source = this.audioCtx.createMediaElementSource(this.voiceAudio)
    this.voiceAudioAnalyser.fftSize = 64
    this.voiceAudioAnalyser.smoothingTimeConstant = 1
    this.voiceAudioAnalyser.connect(this.audioCtx.destination)
    source.connect(this.voiceAudioAnalyser)
  }

  startListening = async () => {
    this.vad = await MicVAD.new({
      additionalAudioConstraints: {sampleRate: 16000, sampleSize: 32, channelCount: 1, echoCancellation: true} as any,
      positiveSpeechThreshold: this.state.threshold,
      onSpeechEnd: async (audio: any) => {
        const text = await (window as any).electronAPI.transcribe(audio)
        if (text !== '') {
          this.send(text)
        }
      }
    })
    this.vad.start()
  }
  
  send = async (text: any) => {
    const screen = await this.getScreen()
    this.socket.emit('textandscreen', {text, screen})
  }

  connect = async () => {
    const token = new URLSearchParams(this.props.location.search).get("token")
    this.socket = io(config.app.wsUri, {
      transports: ["websocket"],
      reconnection: true,
      timeout: 2000,
      auth: { token }
    }).on('connect', () => {
      // none
    }).on('chunk', (data: any) => {
      const index = data.indexOf(".", this.chunksIndex)
      if (index > this.chunksIndex) {
        const toProcess = data.substring(this.chunksIndex, index+1)
        this.chunksIndex = index+1
        this.chunksToProcess.push(toProcess)
      }
    }).on('end', (data: any) => {
      if (this.chunksIndex < data.length) {
        const toProcess = data.substring(this.chunksIndex, data.length)
        this.chunksIndex = 0
        this.chunksToProcess.push(toProcess)
      }
    }).on('outofbalance', () => {
      (window as any).electronAPI.stopRenderer()
    }).on("error", (error) => {
      // error
    }).on("connect_error", (error) => {
      // connect error
    }).on("disconnect", () => {
      // disconnect
    })
  }

  scene: any
  talking: any

  onSceneReady = async (scene: any) => {
    this.scene = scene
    this.scene.clearColor = new Color4(0,0,0,0.0000000000000001);
    // This creates and positions a free camera (non-mesh)
    const camera = new FreeCamera("camera1", new Vector3(0, 1.5, 1.5), this.scene);
    camera.setTarget(new Vector3(0, 1.7, -3.50));
    const canvas = this.scene.getEngine().getRenderingCanvas();
    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);
    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    const light = new HemisphericLight("light", new Vector3(0, 1, 1), this.scene);
    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 1;
    // load models
    await this.importAllAnimations();
  }

  // Setup Animations & Player
  animationsGLB: any[] = [];
  idle1: any
  idle2: any
  idle3: any
  talking1: any
  talking2: any
  talking3: any
  salute: any
  currentAnimation: any
  leftEye: any
  rightEye: any
  jawForward: any
  observer1: any
  observer2: any
  observer3: any

  // Import Animations and Model
  importAllAnimations = async () => {
    const animationPromises = [
      this.importAnimations("F_Standing_Idle_Variations_001.glb"),
      this.importAnimations("F_Standing_Idle_Variations_002.glb"),
      this.importAnimations("F_Standing_Idle_Variations_003.glb"),
      this.importAnimations("F_Talking_Variations_001.glb"),
      this.importAnimations("F_Talking_Variations_004.glb"),
      this.importAnimations("F_Talking_Variations_006.glb")
    ];
    await Promise.all(animationPromises);
  }

  // Import Animations
  importAnimations = (animation: any) => {
    return SceneLoader.ImportMeshAsync(null, "/assets/" + animation, undefined, this.scene)
      .then((result) => {
        result.meshes.forEach(element => {
          if (element) {
            element.dispose();
          }
        });
        this.animationsGLB.push(result.animationGroups[0]);
    });
  }

  // Import Model
  importModel = (model: any) => {
    return SceneLoader.ImportMeshAsync(null, "/assets/" + model, undefined, this.scene)
      .then((result) => {
        const player = result.meshes[0];
        player.name = "_Character_";
        // shadowGenerator.addShadowCaster(result.meshes[0]);

        const modelTransformNodes = player.getChildTransformNodes();

        this.animationsGLB.forEach((animation) => {
          const modelAnimationGroup = animation.clone(model.replace(".glb", "_") + animation.name, (oldTarget: any) => {
            return modelTransformNodes.find((node) => node.name === oldTarget.name);
          });
          animation.dispose();
        });

        // Clean Imported Animations
        this.animationsGLB = [];

        // Setup Idle Anims
        const modelName = model.substring(model.lastIndexOf("/") + 1).replace(".glb", "");
        this.idle1 = this.scene.getAnimationGroupByName(modelName + "_F_Standing_Idle_Variations_001");
        this.idle2 = this.scene.getAnimationGroupByName(modelName + "_F_Standing_Idle_Variations_002");
        this.idle3 = this.scene.getAnimationGroupByName(modelName + "_F_Standing_Idle_Variations_003");
        
        this.talking1 = this.scene.getAnimationGroupByName(modelName + "_F_Talking_Variations_001");
        this.talking2 = this.scene.getAnimationGroupByName(modelName + "_F_Talking_Variations_004");
        this.talking3 = this.scene.getAnimationGroupByName(modelName + "_F_Talking_Variations_006");

        // Current Anim
        this.currentAnimation = this.idle1;
        this.idle1.play(false);

        this.setIdleAnimObservers();

        // setReflections();
        // setShadows();
        this.currentAnimation = this.scene.animationGroups[0];
        // showButtonHide();

        this.leftEye = this.scene.getMeshByName("Wolf3D_Head").morphTargetManager.getTarget(50);
        this.rightEye = this.scene.getMeshByName("Wolf3D_Head").morphTargetManager.getTarget(51);
        this.jawForward = this.scene.getMeshByName("Wolf3D_Head").morphTargetManager.getTarget(9);

        // console.log(this.scene.getMeshByName("Wolf3D_Head").morphTargetManager);

        // Setup Init Jaw Forward
        this.jawForward.influence = 0.4;
        
        // Animate Face Morphs
        this.animateFaceMorphs();

        // RegisterBeforeRender Morph Target Mouth speaking
        this.scene.registerBeforeRender(() => {
          let jawValue = 0;
          if (this.talking) {
            const bufferLength = this.voiceAudioAnalyser.frequencyBinCount
            const dataArray = new Uint8Array(bufferLength)
            this.voiceAudioAnalyser.getByteTimeDomainData(dataArray)
            const range = this.mapRange(dataArray[5], 78, 178, 0.0, 0.5)
            jawValue = range
          }
          this.scene.getMeshByName("Wolf3D_Head").morphTargetManager.getTarget(16).influence = jawValue;
          this.scene.getMeshByName("Wolf3D_Head").morphTargetManager.getTarget(34).influence = jawValue;
          this.scene.getMeshByName("Wolf3D_Teeth").morphTargetManager.getTarget(34).influence = jawValue;
        })
      })
  }
  
  mapRange = (value: number, inMin: number, inMax: number, outMin: number, outMax: number) =>  {
    return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
  }

  wait(ms: number) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  // Animate Face Morphs using intervals
  animateFaceMorphs = () => {

    const mesh = this.scene.getMeshByName("Wolf3D_Head");

    const getRandomNumber = (min: any, max: any) => Math.floor(Math.random() * (max - min + 1)) + min;
    // Animate Eyes
    const animateEyes = async () => {
      const randomNumber = getRandomNumber(1, 2);
      if (randomNumber === 1) {
        this.leftEye.influence = 1;
        this.rightEye.influence = 1;
        await this.wait(100);
        this.leftEye.influence = 0;
        this.rightEye.influence = 0;
        const randomNumber2 = getRandomNumber(1, 2);
        if (randomNumber2 === 1) {
          await this.wait(100);
          this.leftEye.influence = 1;
          this.rightEye.influence = 1;
          await this.wait(100);
          this.leftEye.influence = 0;
          this.rightEye.influence = 0;
        }
      }
    };
    
    // animateMorphTarget registerBeforeRender
    const animateMorphTarget = (targetIndex: any, initialValue: any, targetValue: any, numSteps: any) => {
      let currentStep = 0;
      const morphTarget = mesh.morphTargetManager.getTarget(targetIndex);

      const animationCallback = () => {
        currentStep++;
        const t = currentStep / numSteps;
        morphTarget.influence = Scalar.Lerp(initialValue, targetValue, t);
        if (currentStep >= numSteps) {
          this.scene.unregisterBeforeRender(animationCallback);
        }
      };

      this.scene.registerBeforeRender(animationCallback);
    };

    // Brows
    const animateBrow = () => {
      const random = Math.random() * 0.8;
      const initialValue = mesh.morphTargetManager.getTarget(2).influence;
      const targetValue = random;
      animateMorphTarget(2, initialValue, targetValue, 15);
      animateMorphTarget(3, initialValue, targetValue, 15);
      animateMorphTarget(4, initialValue, targetValue, 15);
    };

    // Smile
    const animateSmile = () => {
      const random = Math.random() * 0.18 + 0.02;
      const initialValue = mesh.morphTargetManager.getTarget(47).influence;
      const targetValue = random;
      animateMorphTarget(47, initialValue, targetValue, 30);
      animateMorphTarget(48, initialValue, targetValue, 30);
    };

    // Mouth Left / Right
    const animateMouthLeftRight = () => {
      const random1 = Math.random() * 0.7;
      const randomLeftOrRight = getRandomNumber(0, 1);
      const targetIndex = randomLeftOrRight === 1 ? 22 : 21;
      const initialValue = mesh.morphTargetManager.getTarget(targetIndex).influence;
      const targetValue = random1;
      animateMorphTarget(targetIndex, initialValue, targetValue, 90);
    };

    // Nose
    const animateNose = () => {
      const random = Math.random() * 0.7;
      const initialValue = mesh.morphTargetManager.getTarget(17).influence;
      const targetValue = random;
      animateMorphTarget(17, initialValue, targetValue, 60);
      animateMorphTarget(18, initialValue, targetValue, 60);
    };

    // Jaw Forward
    const animateJawForward = () => {
      const random = Math.random() * 0.5;
      const initialValue = mesh.morphTargetManager.getTarget(9).influence;
      const targetValue = random;
      animateMorphTarget(9, initialValue, targetValue, 60);
    };

    // Cheeks
    const animateCheeks = () => {
      const random = Math.random() * 1;
      const initialValue = mesh.morphTargetManager.getTarget(32).influence;
      const targetValue = random;
      animateMorphTarget(32, initialValue, targetValue, 60);
      animateMorphTarget(33, initialValue, targetValue, 60);
    };

    setInterval(animateEyes, 800);
    setInterval(animateBrow, 1200);
    setInterval(animateSmile, 2000);
    setInterval(animateMouthLeftRight, 1500);
    setInterval(animateNose, 1000);
    setInterval(animateJawForward, 2000);
    setInterval(animateCheeks, 1200);
  }

  // Setup Idle Animation OnEnd Observers
  setIdleAnimObservers = () => {  
    this.observer1 = this.idle1.onAnimationEndObservable.add(() => {  
      this.scene.onBeforeRenderObservable.runCoroutineAsync(this.animationBlending(this.idle1, 0.8, this.idle2, 0.8, false, 0.02));    
    });
    this.observer2 = this.idle2.onAnimationEndObservable.add(() => {  
      this.scene.onBeforeRenderObservable.runCoroutineAsync(this.animationBlending(this.idle2, 0.8, this.idle3, 0.8, false, 0.02));    
    });
    this.observer3 = this.idle3.onAnimationEndObservable.add(() => {  
      this.scene.onBeforeRenderObservable.runCoroutineAsync(this.animationBlending(this.idle3, 0.8, this.idle1, 0.8, false, 0.02));    
    });
  }

  // Animation Blending
  *animationBlending (fromAnim: any, fromAnimSpeedRatio: any, toAnim: any, toAnimSpeedRatio: any, repeat: any, speed: any, toAnimFrameIn: any = 0, toAnimFrameOut: any = undefined, maxWeight: any = 1) {
    if (!toAnimFrameOut) {
      toAnimFrameOut = toAnim.duration;
    }
    let currentWeight = 1;
    let newWeight = 0;
    fromAnim.stop();
    toAnim.start(repeat, toAnimSpeedRatio, toAnimFrameIn, toAnimFrameOut, false)
    fromAnim.speedRatio = fromAnimSpeedRatio;
    toAnim.speedRatio = toAnimSpeedRatio;
    while(newWeight < maxWeight) {
      newWeight += speed;
      currentWeight -= speed;
      toAnim.setWeightForAllAnimatables(newWeight);
      fromAnim.setWeightForAllAnimatables(currentWeight);
      yield;
    }
    this.currentAnimation = toAnim;
  }
  
  onRender = (scene: any) => {

  };

  removeAnimObservers = () => {  
    this.idle1.onAnimationEndObservable.remove(this.observer1);
    this.idle2.onAnimationEndObservable.remove(this.observer2);
    this.idle3.onAnimationEndObservable.remove(this.observer3);
    this.idle1.stop();
    this.idle2.stop();
    this.idle3.stop();
  }

  beginAnimateSpeech = () => {
    setInterval(()=>{
      if (this.talking && !this.currentAnimation.isPlaying) {
        let newTalkingAnim;
        do {
          const random2 = Math.floor(Math.random() * 3) + 1;
          if (random2 === 1)
            newTalkingAnim = this.talking1;
          else if (random2 === 2)
            newTalkingAnim = this.talking2;
          else if (random2 === 3)
            newTalkingAnim = this.talking3;
        } while (newTalkingAnim === this.currentAnimation);
        this.scene.onBeforeRenderObservable.runCoroutineAsync(this.animationBlending(this.currentAnimation, 0.8, newTalkingAnim, 0.8, false, 0.02, this.animationOffset, newTalkingAnim.duration - this.animationOffset, 0.75));
      }
    }, 1000)
  }

  render () {
    return (
      <>
        <SceneComponent
          // onClick={()=>this.start()}
          style={{
            backgroundColor: 'transparent',
            width: '100vw',
            height: '100vh'
          }}
          antialias
          onSceneReady={this.onSceneReady}
          onRender={this.onRender}
          adaptToDeviceRatio
        />
      </>
    );
  }
}

export default function RendererWithBonus() {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  return <Renderer params={params} navigate={navigate} location={location}/>
}