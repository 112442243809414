import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { storeCredentials } from './utils'

interface SigninProps {
  navigate: any
  params: any
}

type SigninStates = {
  logError: string
  logErrors: any
  logEmail: string
  logPassword: string
}

class Signin extends Component <SigninProps, SigninStates> {
  constructor (props: SigninProps) {
    super(props)
    this.state = {
      logError: '',
      logErrors: {},
      logEmail: '',
      logPassword: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as SigninStates)
  }

  signin = () => {
    fetch(
      config.app.apiUri + '/api/v1/me/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.state.logEmail,
          password: this.state.logPassword
        })
      })
      .then((response) => { return response.json() })
      .then(async (json) => {
        if (json.status === 'success') {
          storeCredentials(json.token)
          this.props.navigate('/dashboard')
        } else {
          this.setState({
            logErrors: json.errors,
            logError: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render () {
    return (
      <div className="flex min-h-full items-center justify-center min-h-screen px-2 pt-10 pb-2 bg-gray-900">
        <div className="w-full max-w-sm space-y-10 max-w-[400px]">
          <div>
            <img className="mx-auto h-10 w-auto" src="/logo.png" alt="Your Company"/>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-100">Sign in account</h2>
          </div>
          <div className="space-y-6">
            <div className="relative -space-y-px rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300"></div>
              <div>
                <label htmlFor="email-address" className="sr-only">Email address</label>
                <input value={this.state.logEmail} onChange={this.inputChange} id="email-address" name="logEmail" type="email" required className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" placeholder="Email address"/>
              </div>
              <div>
                <label htmlFor="password" className="sr-only">Password</label>
                <input value={this.state.logPassword} onChange={this.inputChange} id="password" name="logPassword" type="password" required className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" placeholder="Password"/>
              </div>
            </div>
            <div className="text-sm leading-6 w-full text-center">
              <Link to="/forgot" className="font-semibold text-red-500 hover:text-red-400">Forgot password?</Link>
            </div>
            <div>
              <button onClick={()=>this.signin()} className="flex w-full justify-center rounded-md bg-red-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Sign in</button>
              {this.state.logError ?
                <div className="rounded-md bg-red-50 p-4 mt-5">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-850">Error</h3>
                      <div className="mt-2 text-sm text-red-500">
                        <p>{this.state.logError}</p>
                      </div>
                      <div className="mt-4">
                        <div className="-mx-2 -my-1.5 flex">
                          <button onClick={()=>this.setState({logError: ''})} type="button" className="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">Dismiss</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              : null}
            </div>
          </div>
          <p className="text-center text-sm leading-6 text-gray-500">
            No account?{' '}
            <Link to="/signup" className="font-semibold text-red-500 hover:text-red-400">Create one!</Link>
          </p>
        </div>
      </div>
    );
  }
}

export default function SigninWithBonus() {
   const params = useParams()
   const navigate = useNavigate()
   return <Signin params={params} navigate={navigate}/>
 }