import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { storeCredentials } from './utils'

interface SignupProps {
  navigate: any
  params: any
}

type SignupStates = {
   logError: string
   logErrors: any
   logEmail: string
   logFullname: string
   logPassword: string
   logCupon: string
}

class Signup extends Component <SignupProps, SignupStates> {
  constructor (props: SignupProps) {
    super(props)
    this.state = {
      logError: '',
      logErrors: {},
      logEmail: '',
      logFullname: '',
      logPassword: '',
      logCupon: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as SignupStates)
  }

  signup = () => {
   fetch(
     config.app.apiUri + '/api/v1/me/signup', {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify({
         email: this.state.logEmail,
         fullname: this.state.logFullname,
         password: this.state.logPassword,
         cupon: this.state.logCupon
       })
     })
     .then((response) => { return response.json() })
     .then(async (json) => {
       if (json.status === 'success') {
         storeCredentials(json.token)
         this.props.navigate('/dashboard')
       } else {
         this.setState({
           logErrors: json.errors,
           logError: json.message
         })
       }
     })
     .catch((error) => {
       console.log(error)
     })
 }

  render () {
    return (
      <div className="flex min-h-full items-center justify-center min-h-screen px-2 pt-10 pb-2 bg-gray-900">
        <div className="w-full max-w-sm space-y-10 max-w-[400px]">
          <div>
              <img className="mx-auto h-10 w-auto" src="/logo.png" alt="Your Company"/>
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-100">Sign up for account</h2>
          </div>
          <div className="space-y-6">
            <div className="relative -space-y-px rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300"></div>
            <div>
              <label htmlFor="name" className="sr-only">First Name</label>
              <input value={this.state.logFullname} onChange={this.inputChange} id="name" name="logFullname" type="text" required className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" placeholder="First name"/>
            </div>
            <div>
              <label htmlFor="email-address" className="sr-only">Email address</label>
              <input value={this.state.logEmail} onChange={this.inputChange} id="email-address" name="logEmail" type="email" required className="relative block w-full border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" placeholder="Email address"/>
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input value={this.state.logPassword} onChange={this.inputChange} id="password" name="logPassword" type="password" required className="relative block w-full border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" placeholder="Password"/>
            </div>
            <div>
              <label htmlFor="logCupon" className="sr-only">Cupon</label>
              <input value={this.state.logCupon} onChange={this.inputChange} id="logCupon" name="logCupon" type="text" required className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6" placeholder="Cupon (optional)"/>
            </div>
            </div>
            <div className="text-sm leading-6 w-full text-center">
              <Link to="/forgot" className="font-semibold text-red-500 hover:text-red-400">Forgot password?</Link>
            </div>
            <div>
              <button onClick={()=>this.signup()} className="flex w-full justify-center rounded-md bg-red-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Sign up</button>
              {this.state.logError ?
                <div className="rounded-md bg-red-50 p-4 mt-5">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">Error</h3>
                      <div className="mt-2 text-sm text-red-700">
                        <p>{this.state.logError}</p>
                      </div>
                      <div className="mt-4">
                        <div className="-mx-2 -my-1.5 flex">
                          <button onClick={()=>this.setState({logError: ''})} type="button" className="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">Dismiss</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              : null}
            </div>
          </div>
          <p className="text-center text-sm leading-6 text-gray-500">
              Have account?{' '}
              <Link to="/signin" className="font-semibold text-red-500 hover:text-red-400">Sign in!</Link>
          </p>
        </div>
      </div>
    );
  }
}

export default function SignupWithBonus() {
   const params = useParams()
   const navigate = useNavigate()
   return <Signup params={params} navigate={navigate}/>
 }