import React, { Component } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { config } from './config'
import { getCredentials } from './utils'

interface LoaderProps {
  navigate: any
  params: any
}

type LoaderStates = {

}

class Loader extends Component <LoaderProps, LoaderStates> {
  constructor (props: LoaderProps) {
    super(props)
    this.state = {
      
    }
  }

  componentDidMount(){
    this.checkLogged()
  }

  checkLogged = async () => {
    const token = await getCredentials()
    fetch(
      `${config.app.apiUri}/api/v1/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token
        }
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          this.props.navigate('/dashboard')
        } else {
          this.props.navigate('/signin')
        }
      })
  }
  
  render () {
    return (
      <div></div>
    );
  }
}

export default function LoaderWithBonus() {
   const params = useParams()
   const navigate = useNavigate()
   return <Loader params={params} navigate={navigate}/>
 }